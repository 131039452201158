<template>
  <Modal
    :class="'modal-allowance-type'"
    :title="isEditing ? 'Edit Activity Category' : 'Create Activity Category'"
    :show="show"
    :loadingSubmit="loadingSubmit"
    :isCancel="false"
    :isSubmit="false"
    @hide="hide"
  >
    <template #form>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form
          @submit.prevent="handleSubmit(onSubmit)"
          class="is-label-14-px-normal form-timeoff-type"
          multipart
        >
          <div class="columns is-multiline">
            <div class="column is-12">
              <ValidationProvider
                name="Category Name"
                v-slot="{ errors }"
                rules="required"
              >
                <b-field
                  label="Category Name"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-input
                    v-model="formData.name"
                    placeholder="Category Name"
                  ></b-input>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-12">
              <ValidationProvider name="Description" v-slot="{ errors }">
                <b-field
                  label="Description"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-input
                    v-model="formData.description"
                    placeholder="Description"
                  ></b-input>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-12">
              <b-field class="is-flex is-justify-content-center login-cta mb-1">
                <b-button
                  class="is-fullwidth"
                  native-type="submit"
                  :loading="loadingSubmit"
                >
                  {{ isEditing ? 'Submit' : 'Add New' }}
                </b-button>
              </b-field>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </Modal>
</template>

<script>
import Modal from '../../../components/Modals/Modal.vue'
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('activityCategory')

export default {
  components: { Modal },

  data() {
    return {
      ...mapState([]),
    }
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: null,
    },
    loadingSubmit: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    /**
     * Emit hide modal component.
     */
    hide() {
      this.$emit('hide')
    },

    /**
     * Emit Submit modal.
     */
    onSubmit() {
      this.$emit('submit')
    },
  },
}
</script>
