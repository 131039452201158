<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Activity Category</h2>
    </template>
    <template #action-bar>
      <div class="level">
        <div class="level-left">
          <b-button
            tag="button"
            class="is-command"
            icon-left="plus"
            @click.native="openCreateEditModal()"
          >
            New
          </b-button>
        </div>
      </div>
    </template>
    <template #page-content>
      <div id="table-reimbursement-setting" class="table-timeoff-type">
        <b-table
          class="employment-directory-table"
          :data="data"
          :loading="isLoading"
          hoverable
          narrowed
          sticky-header
          paginated
          backend-pagination
          :total="totalData"
          :per-page="perPage"
          @page-change="onPageChange"
          backend-sorting
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortBy, sortOrder]"
          @sort="onSort"
        >
          <template>
            <b-table-column label="No" v-slot="props" width="5%">
              <span class="is-capitalize">
                {{ perPage * (page - 1) + props.index + 1 }}
              </span>
            </b-table-column>

            <b-table-column
              field="name"
              label="Category Name"
              v-slot="props"
              sortable
              width="67%"
            >
              <span class="is-capitalize">
                {{ props.row.name }}
              </span>
            </b-table-column>

            <b-table-column label="Action" v-slot="props" width="8%">
              <b-icon
                type="is-primary"
                icon="pencil"
                custom-size="mdi-18px"
                class="employment-edit-icon"
                @click.native="openCreateEditModal(props.row)"
              ></b-icon>
              <b-icon
                type="is-primary"
                icon="delete"
                custom-size="mdi-18px"
                class="timeoff-type-delete-icon"
                @click.native="openDeleteModal(props.row.id)"
              ></b-icon>
            </b-table-column>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>No data found</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>

      <CreateEditModal
        :formData="formData"
        :show="isCreateEditModalOpen"
        :loadingSubmit="isSubmitting"
        :isEditing="isEditing"
        @hide="closeCreateEditModal"
        @submit="createEditActivityCategory"
      />
    </template>
  </r-page>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import CreateEditModal from './CreateEditModal.vue'

const { mapState, mapActions, mapMutations } = createNamespacedHelpers(
  'activityCategory'
)

export default {
  components: {
    CreateEditModal,
  },
  mounted() {
    this.getActivityCategory()
  },
  computed: {
    ...mapState([
      // Activity Category Table
      'data',
      'isLoading',
      'sortBy',
      'sortOrder',
      'defaultSortOrder',
      'page',
      'perPage',
      'totalData',

      // Create Edit Modal
      'formData',
      'isCreateEditModalOpen',
      'isEditing',
      'isSubmitting',
    ]),
  },
  methods: {
    ...mapActions([
      // Activity Category Table
      'getActivityCategory',

      // Create Edit Modal
      'openCreateEditModal',
      'closeCreateEditModal',
      'createEditActivityCategory',

      // Delete Modal
      'openDeleteModal',
      'deleteActivityCategory',
    ]),
    ...mapMutations(['setSort', 'setPage']),
    /**
     * Handle Activity Category table sort event
     * @param {String} field
     * @param {String} order
     */
    onSort(field, order) {
      this.setSort({ field, order })
      this.getActivityCategory()
    },
    /**
     * Handle Activity Category table pagination event
     * @param {Number} page
     */
    onPageChange(page) {
      this.setPage(page)
      this.getActivityCategory()
    },
  },
}
</script>
